<template lang="pug">
.k-form-container(
  :is="component"
  :title="title"
  :subtitle="subtitle"
  :loading="loading"
  :options="options"
)
  template(#header)
    slot(name="header")
  template(#body)
    slot(name="body")
  template(#footer)
    slot(name="footer")
</template>

<script setup>
import { ref, computed } from 'vue'
import AdminContentBox from './k_form_container/admin-content-box.vue'
import AdminEmbedBox from './k_form_container/admin-embed-box.vue'
import AdminSearch from './k_form_container/admin-search.vue'
import AdminModalBox from './k_form_container/admin-modal-box.vue'
import AdminLightCard from './k_form_container/admin-light-card.vue'
import KolcenterCard from './k_form_container/kolcenter-card.vue'
import KolcenterModalBox from './k_form_container/kolcenter-modal-box.vue'

const STYLE_TYPE_MAP = {
  'admin-content-box': AdminContentBox,
  'admin-embed-box': AdminEmbedBox,
  'admin-search': AdminSearch,
  'admin-modal-box': AdminModalBox,
  'admin-light-card': AdminLightCard,
  'kolcenter-card': KolcenterCard,
  'kolcenter-modal-box': KolcenterModalBox
}

const props = defineProps({
  styleType: { type: String },
  title: { type: String },
  subtitle: { type: String },
  loading: { type: Boolean },
  options: { type: Object }
})

const component = computed(() => {
  const comp = STYLE_TYPE_MAP[props.styleType]

  if (!comp) return console.error('invalid style-type for k-form-container')

  return comp
})
</script>
