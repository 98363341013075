import * as types from './mutation-types'
import PromoterMember from '../../resource_models/promoter_member'

export * from './promoter_shares/actions.js'
export * from './promoter_bank_account_infos/actions.js'
export * from './promoter_profiles/actions.js'
export * from './promoter_rate_records/actions.js'
export * from './promoter_media_accounts/actions.js'
export * from './promoter_preferred_category_ships/actions.js'
export * from './promoter_partner_interests/actions.js'
export * from './line_bot_accounts/actions.js'
export * from './line_bot_account_link_tokens/actions.js'
export * from './reward_accounts/actions.js'
export * from './buyout_orders/actions.js'
export * from './buyout_order_items/actions.js'
export * from './videos/actions.js'
export * from './partners/actions.js'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PromoterMember.all(options)
      .then((response) => {
        commit(types.FETCH_PROMOTER_MEMBERS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromoterMember.find(id)
      .then((response) => {
        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.GET_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTER_MEMBER_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PROMOTER_MEMBER_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const signUp = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'updatePhotos')

  return new Promise((resolve, reject) => {
    PromoterMember.signUp(model)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: signUp,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// update bg_image
export const updateBgImage = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updatePhotos')

  return new Promise((resolve, reject) => {
    model
      .updateBgImage(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBgImage,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              form
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// update photos
export const updatePhotos = ({ dispatch, commit }, { model, formData }) => {
  commit(types.API_REQUEST_START, 'updatePhotos')

  return new Promise((resolve, reject) => {
    model
      .updatePhotos(formData)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updatePhotos,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// update password
export const updatePassword = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updatePassword')

  return new Promise((resolve, reject) => {
    model
      .updatePassword()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updatePassword,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const shotGunUpdate = ({ dispatch, commit }, { model, attributes }) => {
  commit(types.API_REQUEST_START, 'shotGunUpdate')

  return new Promise((resolve, reject) => {
    model
      .shotGunUpdate(attributes)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: shotGunUpdate,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              attributes
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const getPhoneVerificationCodeSms = (
  { dispatch, commit },
  { model, data }
) => {
  commit(types.API_REQUEST_START, 'getPhoneVerificationCodeSms')

  return new Promise((resolve, reject) => {
    model
      .getPhoneVerificationCodeSms(data)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getPhoneVerificationCodeSms,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              data
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const verifyPhone = (
  { dispatch, commit },
  { model, verificationCode }
) => {
  commit(types.API_REQUEST_START, 'verifyPhone')

  return new Promise((resolve, reject) => {
    model
      .verifyPhone(verificationCode)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: verifyPhone,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              verificationCode
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const tourKolcenter = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'tourKolcenter')

  return new Promise((resolve, reject) => {
    model
      .tourKolcenter()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: tourKolcenter,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTER_MEMBERS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PROMOTER_MEMBER_SUCCESS, response)

    resolve(response)
  })
}

export const getMatchInfo = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'getMatchInfo')

  return new Promise((resolve, reject) => {
    model
      .getMatchInfo()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getMatchInfo,
            ref: {
              dispatch,
              commit
            },
            params: { model }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleVerification = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'getMatchInfo')

  return new Promise((resolve, reject) => {
    model
      .toggleVerification(form)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, response)
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleVerification,
            ref: {
              dispatch,
              commit
            },
            params: { model }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
