import * as types from '../mutation-types'

export const updateVideo = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateVideo')

  return new Promise((resolve, reject) => {
    model
      .updateVideo(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        dispatch('videos/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateVideo,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              form
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
