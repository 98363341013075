import * as types from '../mutation-types'

export const fetchPartnerInterests = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchPartnerInterests')

  return new Promise((resolve, reject) => {
    model
      .fetchPartnerInterests(options)
      .then((response) => {
        dispatch(
          'promoterPartnerinterests/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('partners/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchPartnerInterests')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPartnerInterests,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateInterest = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateInterest')

  return new Promise((resolve, reject) => {
    model
      .updateInterest(form)
      .then((response) => {
        dispatch('interests/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'updateInterest')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateInterest,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
