import * as types from '../mutation-types'

export const fetchStoreFrontCampaigns = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchStoreFrontCampaigns')

  return new Promise((resolve, reject) => {
    model
      .fetchStoreFrontCampaigns(options)
      .then((response) => {
        dispatch(
          'promoterCampaigns/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchStoreFrontCampaigns')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchStoreFrontCampaigns,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchInterestedPartnerOpenCampaigns = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchInterestedPartnerOpenCampaigns')

  return new Promise((resolve, reject) => {
    model
      .fetchInterestedPartnerOpenCampaigns(options)
      .then((response) => {
        dispatch(
          'promoterCampaigns/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchInterestedPartnerOpenCampaigns')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchInterestedPartnerOpenCampaigns,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
