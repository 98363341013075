import PromoterMember from '../../../resource_models/promoter_member'
import * as types from '../mutation-types'

export const getCurrentKolShare = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'getCurrentKolShare')

  return new Promise((resolve, reject) => {
    PromoterMember.getCurrentKolShare(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getCurrentKolShare')
        // MARK: retryAction issue
        dispatch('promoterEvents/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterMembers/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('promoterShares/setCurrentKolShareId', response.data.data.id, {
          root: true
        })

        dispatch(
          'promoterShareLevels/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('promoterLevels/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('videos/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch(async (errors) => {
        // MARK: retryAction issue
        commit(types.API_REQUEST_FAIL, errors)

        const result = await dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getCurrentKolShare,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        return result?.status === 200 ? resolve(result) : reject(result)
      })
  })
}
